import { render, staticRenderFns } from "./myfamily.vue?vue&type=template&id=7957bc80&scoped=true&"
import script from "./myfamily.vue?vue&type=script&lang=js&"
export * from "./myfamily.vue?vue&type=script&lang=js&"
import style0 from "./myfamily.vue?vue&type=style&index=0&id=7957bc80&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7957bc80",
  null
  
)

export default component.exports